import React from "react";
import Tab from "../components/tab";
import { Card} from "react-bootstrap";
import Backgroundimg from "../images/3.jpg";



function Products() {
    return (
        <>
            <Card className='mainheader'>
                <Card.Img src={Backgroundimg} alt="Card image" className="backgroundimg" />
                <Card.ImgOverlay>
                    <Card.Title><h2>Products</h2></Card.Title>
                </Card.ImgOverlay>
            </Card>
            <Tab />

        </>
    )
}
export default Products