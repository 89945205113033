export const navItems = [
    {
      id: 1,
      title: "Home",
      path: "./innokempharma",
      cName: "nav-item",
    },
    {
        id: 2,
        title: "AboutUs",
        path: "./aboutus",
        cName: "nav-item",
      },
    {
      id: 3,
      title: "Services",
      cName: "nav-item",
    },
    {
      id: 4,
      title: "Products",
      path: "./products",
      cName: "nav-item",
    },
    {
      id: 5,
      title: "Contact Us",
      path: "./contactus",
      cName: "nav-item",
    },
  ];
  export const serviceDropdown = [
    {
      id: 1,
      title: "Analytical Testing Services",
      path: "./analyticaltestingservices",
      cName: "services-submenu-item",
    },
    {
        id: 2,
        title: "Some Testing Services",
        path: "./analyticaltestingservices",
        cName: "services-submenu-item",
      }
  ];