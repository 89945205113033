import React, {useState} from "react";
import "./navbar.css"    
import { Link } from "react-router-dom" 
import { navItems } from "./navitems"
import { Dropdown } from "./dropdown"
import Image from 'react-bootstrap/Image';
import Logo from "../images/Logo.png"

function ReactNavbar() {

    const [dropdown, setDropdown] = useState(false);

    return (
        <>
        <nav className="navbar">
             <Link to="/innokempharma" className="navbar-logo"><Image src={Logo} className="Imglogo"/></Link>
            <ul className="navbar-items">
            {navItems.map((item) => {
                if (item.title === "Services") {
                    return (
                        <li
                        key={item.id}
                        className={item.cName}
                        onMouseEnter={() => setDropdown(true)}
                        onMouseLeave={() => setDropdown(false)}
                        >
                            <Link to={item.path}>
                                {item.title}
                                {dropdown && <Dropdown serviceDropdown={item.serviceDropdown} />}
                            </Link>
                        </li>
                    );
                }
                return(
                    <li key={item.id} className={item.cName}>
                    <Link to={item.path}>
                        {item.title}
                    </Link>
                </li>
                )   
                })}
            </ul>
        </nav>
       
        </>
    )
}

export default ReactNavbar;