import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { Container, Row, Col, Nav } from "react-bootstrap";
import Transitions from './fade';
import "./tab.css";


function tab() {
  return (
    <Tabs
      defaultActiveKey="Pharma"
      id="products-tab"
      className="mb-3 products-tab"
      justify
    >
      <Tab eventKey="Pharma" title="Pharma">
        <Transitions/>  
      </Tab>
      <Tab eventKey="Homeopathy" title="Homeopathy">
        Tab content for Homeopathy
      </Tab>
      <Tab eventKey="Allopathy" title="Allopathy">
        Tab content for Allopathy
      </Tab>
    </Tabs>
  );
}

export default tab;