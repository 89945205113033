import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Form, Row, Col, Card } from 'react-bootstrap';
import Backgroundimg from "../images/3.jpg";
import { Map, Envelope, Telephone } from 'react-bootstrap-icons';
import "./main.css";
import "./Contactus.css";

const initialFormState = {
  from_name: '',
  email: '',
  number: '',
  message: ''
};

const Contactus = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [formState, setFormState] = useState({
    isSubmitting: false,
    message: null,
    validated: false
  });
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const { from_name, email, number, message } = formData;
    return from_name.trim() !== '' && 
           /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && 
           /^\d{10}$/.test(number) && 
           message.trim() !== '';
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setFormState(prev => ({ ...prev, validated: true }));
      return;
    }

    setFormState(prev => ({ ...prev, isSubmitting: true }));

    try {
      await emailjs.sendForm(
        'service_kt8m5sb', 
        'template_g85bprn', 
        formRef.current, 
        'yOVGezd2mA7jRTFOS'
      );

      setFormState({
        isSubmitting: false,
        message: 'Message sent!',
        validated: false
      });
      setFormData(initialFormState);
      formRef.current.reset();

      setTimeout(() => {
        setFormState(prev => ({ ...prev, message: null }));
      }, 5000);
    } catch (error) {
      setFormState({
        isSubmitting: false,
        message: 'Something went wrong, please try again later',
        validated: false
      });

      setTimeout(() => {
        setFormState(prev => ({ ...prev, message: null }));
      }, 5000);
    }
  };

  return (
    <>
      <Card className='mainheader'>
        <Card.Img src={Backgroundimg} alt="Card image" className="backgroundimg" />
        <Card.ImgOverlay>
          <Card.Title><h2>Contact Us</h2></Card.Title>
        </Card.ImgOverlay>
      </Card>

      <Container>
        <Row>
          <Col md={4}>
            <Map className='contact-icons' />
            <div className='contact-icons-body'>
              <h5>Location</h5>
              <p>H.No: 4-7-15/55/1/202, Flat No:202, St. No:4, Sri Raghavendra Nagar
                Nacharam, Hyderabad - 76, Andhra Pradesh, India</p>
            </div>
            <Telephone className='contact-icons' />
            <div className='contact-icons-body'>
              <h5>Phone</h5>
              <p>040 - 6517 6666, (M) 86888 64666.</p>
            </div>
            <Envelope className='contact-icons' />
            <div className='contact-icons-body'>
              <h5>Email</h5>
              <p>info@innokempharma.com</p>
            </div>
          </Col>
          <Col md={8}>
            <Form ref={formRef} noValidate validated={formState.validated} className='form-container' onSubmit={sendEmail}>
              <Form.Group className="mb-3" controlId="user_name">
                <Form.Label>Name</Form.Label>
                <Form.Control 
                  type="text" 
                  name="from_name" 
                  value={formData.from_name}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide your name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="user_email">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  name="email" 
                  value={formData.email}
                  onChange={handleInputChange}
                  required 
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="user_number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control 
                  type="number" 
                  name="number" 
                  value={formData.number}
                  onChange={handleInputChange}
                  required 
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid phone number.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                  as="textarea" 
                  rows={3} 
                  placeholder="Enter your text.." 
                  name='message'
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a message.
                </Form.Control.Feedback>
              </Form.Group>
              <input 
                className="Submit" 
                type="submit" 
                value="Submit" 
                disabled={formState.isSubmitting} 
              />
              {formState.message && <p>{formState.message}</p>}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contactus;
