import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Logo from "../images/Logo.png"
import "./footer.css"

function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                <Row className="footer-row align-items-center">
                    <Col xs={12} md={4} className="text-center text-md-left mb-3 mb-md-0">
                        <Image src={Logo} alt="Company Logo" className="footer-img" />
                    </Col>
                    <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
                        <p>&copy; {new Date().getFullYear()} Innokem Pharma</p>
                        <h5>Address</h5>
                        <h6>H.No: 4-7-15/55/1/202, Flat No:202, St. No:4, Sri Raghavendra Nagar
                        Nacharam, Hyderabad - 76, Andhra Pradesh, India</h6>
                    </Col>
                    <Col xs={12} md={4} className="text-center text-md-right">
                        <h6>Email: info@innokempharma.com</h6>
                        <h6>Ph: 040 - 6517 6666, (M) 86888 64666.</h6>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
